*{
    margin:0;
    
}
@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100;200;300;400;500;600;700;800;900&display=swap');
a {
  color: white;
}

/* header */

.header {
  background-color: rgba(0, 0, 0, 0);
  box-shadow: 1px 1px 4px 0 rgba(0,0,0,.1);
  position: fixed;
  width: 100%;
  z-index: 3;
  align-items: center;
  font-family: 'Lexend', sans-serif;
}

.header ul {
  margin: 0;
  padding: 0;
  align-items: center;
  list-style: none;
 overflow:auto;
 align-items: center;
  background-color: black;
}

.header li a {
  display: block;
  padding: 20px 20px;
 
  text-decoration: none;
}

.header li a:hover,
.header .menu-btn:hover {
    cursor:pointer;
  background-color: rgba(0, 0, 0, 0.255);
}
.logoimg{
    width:10vw;
  
}
@media only screen and (max-width:768px){
  .logoimg{
    width:35vw;
  }
}
.header .logo {
  display: block;
  float: left;
  
  padding: 20px 20px;
  text-decoration: none;
}

/* menu */

.header .menu {
  clear: both;
  max-height: 0; align-items: center;
  transition: max-height .2s ease-out;
  font-size:1em;
}

/* menu icon */

.header .menu-icon {
  cursor: pointer;
  display: inline-block;
  float: right;
  padding: 28px 20px;
  position: relative;
  user-select: none;
}

.header .menu-icon .navicon {
  background: white;
  display: block;
  height: 2px;
  position: relative;
  transition:  .2s ease-out;
  width: 18px;
}


.header .menu-icon .navicon:before,
.header .menu-icon .navicon:after {
  background: white;
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  transition: all .2s ease-out;
  width: 100%;
}

.header .menu-icon .navicon:before {
  top: 5px;
}

.header .menu-icon .navicon:after {
  top: -5px;
}

/* menu btn */

.header .menu-btn {
  display: none;
}

.header .menu-btn:checked ~ .menu {
  max-height: 240px;
}

.header .menu-btn:checked ~ .menu-icon .navicon {
  background: transparent;
}

.header .menu-btn:checked ~ .menu-icon .navicon:before {
  transform: rotate(-45deg);
}

.header .menu-btn:checked ~ .menu-icon .navicon:after {
  transform: rotate(45deg);
}

.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
  top: 0;
}

/* 48em = 768px */

@media (max-width:10px ) {
  .header li {
    float: left;
    
  }
  .header li a {
    padding: 20px 30px;
  }
  .header .menu {
    clear: none;
    float: right;
    max-height: none;
  }
  .header .menu-icon {
    display: none;
  }
}



@media only screen and (max-width:768px){
  .navicon{
  scale:2;
  margin-right:2vw;
  margin-top:2vw;
  }
}
