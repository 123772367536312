@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.section1{
    background-image: url('https://img001.prntscr.com/file/img001/Uoh95hGWRo2CEE23-NilJw.png');
    background-size: cover;
    background-position: center;
    width: 100%;
    height:52vw;
    position:relative;
   
   
}

.body{
    overflow: hidden;
}
.section1_div{
    display: flex;
  padding-top:5%;
    padding-bottom: 5%;
   
    color:white;
    
    font-family: 'Poppins', sans-serif;


}

.section1 ul{
    
    list-style: none;
   margin-top:15%;
   margin-left:-1vw;
   line-height: 4vw;
  

}
.section1 ul li a img{
    width:2.5vw;
 
}
.section1_div{
   text-align: center;
   width:fit-content;

}
.section1_div_text{
  justify-content: center;
  align-items: center;
}
.section1_div_text h1{
  font-size: 7vw;
  text-align: center;
  margin-left:9vw;
    margin-top:9vw;
  width:70vw;
  line-height: 110%;
}
.section1_div_text p{
    font-size: 1.3vw;
    padding-top:5%;
    padding-left:2.5vw;
    padding-right:2.5vw;
    width:80vw;
    padding-bottom:5%;
    
}
.section1_button{
    width:20vw;
    
    background-color: rgba(0, 0, 0, 0);
    border-radius: 3vw;
    padding:1.5vw;
    font-family: 'Poppins', sans-serif;
    font-size:1.5vw;
   border:2px solid white;
   color:white;

   
}
.section1_button:hover{
    background-color: white;
    color:black;
    transition: 0.7s;
}


.section2{
    background-color: black;
    padding-bottom:5%;
    display:flex;
    align-items: center;
    justify-content: center;
   
}


/*carousel*/
.carousel{
    width:100%;
   

 
}

  .carousel_card{
  color:white;
    background-color: #031730;
  width:23vw;
 
 
    border-radius: 3vw;
   padding-right:1vw;
   padding-top:3vw;
   height:25vw;
   margin-right:3vw;
   text-align: left;
 
    font-family: 'Poppins', sans-serif;
    
  }
  .carousel_card ul{
    padding-left:2vw;
    margin-bottom:1vw;
  }
  .carousel_card:hover{
   
 
    background-color:  hsla(217, 100%, 50%, 1);

    background: linear-gradient(90deg, hsla(217, 100%, 50%, 1) 0%, hsla(186, 100%, 69%, 1) 100%);
    
    background: -moz-linear-gradient(90deg, hsla(217, 100%, 50%, 1) 0%, hsla(186, 100%, 69%, 1) 100%);
    
    background: -webkit-linear-gradient(90deg, hsla(217, 100%, 50%, 1) 0%, hsla(186, 100%, 69%, 1) 100%);
    
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#0061FF", endColorstr="#60EFFF", GradientType=1 );;
  }
  
.carousel_card_title{
    font-size:2.5vw;

}
.carousel_card_p{
    font-size:0.8vw;
}
  .carousel_card ul li{
    list-style: none;
  }
  .carousel_card_icon img{
    width: 5vw;
    
  }
  .section3{
    background-color: #010912;
    color:white;
    padding-top:5%;
    padding-bottom:5%;
    font-family: 'Poppins', sans-serif;
  }
  .section3_div1{
    display: flex;
    align-items: center; 
  }
  .section3_div1 p{
    font-size:2vw;
  }
  .title_line{
    width:5vw;
    margin-left:3% ;
    margin-right:2%;
    height:0.4vw;
    background-color: #0B94B8;
  }
  .section3 h1{
    margin-left:3vw;
    font-size:3vw;
  }
  .section3_p{
    margin-left:3vw;
    margin-bottom:1vw;
    font-size:1.1vw;
  }

.carousel2 div{
  height:100%;

}
.carousel2 div img{
   scale:0.7;


}

.section4{
    padding-top:5vw;
    background-color: black;
    color:white;
    position:relative;
    padding-bottom:5%;
    font-family: 'Poppins', sans-serif;
  
}
.section4 h1{
    margin-left:3vw;
    font-size:3vw;
}

.elipse1{
margin-top:10vw;
    border:2.5vw solid #06D2FA;
    width: 70vw;
    height:70vw;
    border-radius: 50%;
    display: -webkit-box;

margin-left: 60%;
}
.elipse2{
    border:2vw solid #06D2FA;
    width: 60vw;
    height:60vw;
    border-radius: 50%;
    display: -webkit-box;
margin-top: 3vw;
margin-left: 6%;
}
.elipse3{
    border:1.5vw solid #06D2FA;
    width: 50vw;
    height:50vw;
    border-radius: 50%;
    display: -webkit-box;
margin-top: 3vw;
margin-left: 6%;
}
.elipse4{
    border:1vw solid #06D2FA;
    width: 40vw;
    height:40vw;
    border-radius: 50%;
    display: -webkit-box;
margin-top: 3vw;
margin-left: 6%;
}
.elipse5{
    border:0.5vw solid #06D2FA;
    width: 30vw;
    height:30vw;
    border-radius: 50%;
    display: -webkit-box;
margin-top: 3vw;
margin-left: 6%;
}
.elipse6{
    border:0.5vw solid #06D2FA;
    width: 25vw;
    height:25vw;
    border-radius: 50%;
    display: -webkit-box;
margin-top: 2vw;
margin-left: 10%;
}
.section4_gallery{
    color:white;
}
.image_container{
    margin-top:-80vw;
    display:flex;
    flex-wrap: wrap;
    justify-content: center;
    gap:10px;
    width:100%;
}
.image_container img{
    border-radius: 4vw;
    width:25vw;
    margin-left:20px;
    

}
.image_container img:hover{
   opacity: 0.7;
}
.testimonal_card{
    background-color:  hsla(217, 100%, 50%, 1);

    background: linear-gradient(90deg, hsla(217, 100%, 50%, 1) 0%, hsla(186, 100%, 69%, 1) 100%);
    
    background: -moz-linear-gradient(90deg, hsla(217, 100%, 50%, 1) 0%, hsla(186, 100%, 69%, 1) 100%);
    
    background: -webkit-linear-gradient(90deg, hsla(217, 100%, 50%, 1) 0%, hsla(186, 100%, 69%, 1) 100%);
    
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#0061FF", endColorstr="#60EFFF", GradientType=1 );;
    width:40vw;
    height:25vw;
    padding:3vw;
    border-radius: 4vw;
    display: flex;
    flex-direction: column;
    font-size:1.5vw;
  }
  .testimonal_card p{
    margin-top:1vw; 
    font-size: 1.3vw;
  }
.testimonal_card_user{
    display:flex;
    flex-direction: row;
    align-items: center;
    text-align: left;
    font-size:1.3vw;
}
.testimonal_card_user>img{
  width:5vw !important;
  height: 5vw !important;
  border-radius: 50%;
}
.testimonal_card_user_texts{
 margin-left:20px;
}

.carousel3{
    margin-top:6vw;
}
.news_button{
    width:9vw;
    background-color: #0B94B8;
    color:white;
    padding:1vw;
    border-radius: 1vw;
    border:none;
    cursor: pointer;
    margin-left:70%;
    font-size:1.2vw;
}
.news_container{
 margin-top:-83vw;
 justify-content: center;
 align-items: center;
  width:100%;
   height:100%;
  
  display: flex;
  flex-wrap:wrap;

}
.news_card{
  float: left;
  clear: both;
  margin-right:0;
  margin-left:0;
  margin-top:2vw;
   background-size:cover;
   background-position: center;
 width:50vw;
 margin-right:-10vw;
 overflow: hidden;
box-sizing: border-box;

 position:relative;
  height:100% !important;display: flex;
 
}
.news_card img{
  width:57%;
  height:20%;
  border-radius:3vw;
}
.news_paragraph{
    width:51%;
    padding:3%;
    padding-bottom:6%;
    height:20%;
    left:0;
   
    overflow: hidden;
    font-size :1vw;
   position:absolute;
    background-color: #07d2fbd2;
    border-bottom-left-radius: 3vw;
    bottom:0%;
    border-bottom-right-radius: 3vw;
}
.command_card{
    background-color: #031730;
    width:90%;
    height:30vw;
    padding:3vw;
    border-radius: 3vw;
    display: flex;
    
    flex-direction: column;
    font-size :1.1vw;
}
.xidmet_karusel_img img{
  border-radius: 7vw;
  height:100%;
}

.command_card p{
  margin-top:1vw;
}
.mobile{
  display:none;
}
.pc{
  display:block;
}
@media only screen and (max-width:768px){

  .section1{
  height:170vw;

  }
  .section1_div{
   margin-left:-4.5vw
  }
  .section1_div ul li a img{
    width:4vw;
  }

  .section1_div ul li{
    margin-bottom:2vw;
    z-index:99999;
  }
  .section1_div ul{
    left:0vw;
     top:14%;
   
     position:absolute;
  }
  #elaqe{
    margin-left:6vw;
    font-size:3vw;
  }
  .section1_div_text{
   margin-top:45vw;
   margin-left:5vw;

     }
   .section1_div_text h1{
    width:80%;
    
    font-size:9vw;
   }
   .section1_button{
    margin-left:-6vw;
    scale:1.4;
    z-index:-1;
   }
 .section2{
    margin-top:-50vw;
    z-index: 0.1;
    position:relative;
    background-color: #01091200;
 }
   .carousel_card{
    width:27vw;
   
    height:40vw;  overflow: scroll;
   }
   .carousel_card_title{
    font-size:3vw;
   }
   .carousel_card_p{
    font-size:2.5vw;
   }
   .section3_div1>p{
    font-size:4vw;
  
   }
   .section3 h1{
    font-size:5vw;
   }
   .section3_p{
    font-size:2.5vw;
   }
   .carousel2 div img{
    scale:0.9
   }
   .section4 h1{
    font-size:5vw;
   }
   .news_card{
    width:110vw;
    margin-left:20vw;
   }
   .news_paragraph{
    overflow: scroll;
    font-size:2.5vw;
   }
   .command_card{
    width:90%;
    height:50vw;
   
 
   }
   .news_button{
    width:20vw;
    padding:2vw;
    font-size:3vw;
  }
   
   .carousel3{
    margin-left:3vw;
   
   }
   #command_comment{
    font-size:3vw; overflow: scroll;
    text-align: left;
    margin-top:3vw;
   }
   .testimonal_card_user_texts{
     font-size:3vw;
   }
   #sulman_hoca{
    scale: 2.5;
    margin-left:3vw;
    margin-right:3vw;
   }
   #mobile_carousel .command_card{
    display:block;
   }
   .pc{
    display:none;
   }
   .mobile{
    display: block;
   }
}

@media only screen and (max-width:450px){
  .section1_div ul{
    left:-6vw;
    top:7%;
  
    position:absolute;
  }
}
